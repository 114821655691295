import React from "react"
//import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import styles from "./about.module.scss"

import logoJWC from "../images/JWC_logo--black.svg"

function PageAbout() {

  const PageAboutData = useStaticQuery(graphql`
  {
    allWordpressPage(filter: {wordpress_id: {eq: 445}}) {
      edges {
        node {
          content
        }
      }
    }
  }
`)
  // debug
  //console.log(PageAboutData)

  const PageAboutDataContent = PageAboutData.allWordpressPage.edges[0].node.content

  return (
    <Layout>
      <SEO title="Collection" />

      <div className={'flex center middle ' + styles.wrapper}>
        <div className={'col col-8 col-m-9 col-s-12'}>
          <div className={styles.content}>
            <img className={styles.logo} src={logoJWC} alt={"Ji Won Choi - logo"} />
            <div dangerouslySetInnerHTML={{ __html: PageAboutDataContent }}></div>
          </div>
        </div>
      </div>

    </Layout>
  )
}
export default PageAbout
